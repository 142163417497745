<template>
  <v-container fill-height>
    <v-layout align-center justify-center>
      <v-flex xs12 sm12 md12 lg10>
        <v-sheet color="white" elevation="20" class="pa-2">
          <v-row class="pt-2 pb-2">
            <v-col cols="12" md="6">
              <v-card height="100%" elevation="0">
                <v-img
                  v-if="this.doctype === 'invoice'"
                  contain
                  height="60px"
                  src="../assets/dian.png"
                />
                <v-card-title>
                  <h3>
                    <v-select
                      v-model="doctype"
                      hide-details
                      :items="doctypes"
                      item-value="value"
                      item-text="label"
                      label=""
                      placeholder="Tipo Documento"
                      outlined
                      @change="validate_invoice"
                    />
                  </h3>
                </v-card-title>
                <v-card-text>
                  <b>{{ this.store.PartyName }}</b>
                  <br />
                  {{ this.store.store_address + " , " + this.store.store_city }}<br />
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="12" md="6">
              <v-card elevation="1" class="pa-2">
                <v-toolbar flat dense>
                  <H2>CLIENTE SISTECREDITO</H2>
                  <v-spacer></v-spacer>
                  <v-btn icon fab @click="client_dialog = !client_dialog">
                    <v-icon> mdi-magnify </v-icon>
                  </v-btn>
                </v-toolbar>

                Nombre: {{ this.client.PartyName }} <br />
                {{ get_name_typeId(this.client.typeid) }} :
                {{ this.client.PartyIdentification }}<br />
                Dirección: {{ this.client.AddressLine }},
                {{ get_name_city(this.client.CityName) }} <br />
                Email: {{ this.client.client_email }}<br />
              </v-card>
            </v-col>
            <v-col cols="12" md="12">
              <v-data-table
                :headers="headers_cart"
                :items="cart"
                :items-per-page="-1"
                class="elevation-1"
                dense
                calculate-widths
                mobile-breakpoint="0"
                item-key="ean"
                :loading="loading_status"
                loader-height="10"
                loading-text="Cargando ..."
                hide-default-footer
              >
                <template v-slot:top>
                  <v-toolbar flat>
                    <v-toolbar-title>Productos</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn fab icon @click="search_dialog = !search_dialog">
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </v-toolbar>
                </template>
                <template v-slot:[`item.cantidad`]="{ item }">
                  <v-btn fab icon @click="editItem(item)">
                    {{ item.cantidad }}
                  </v-btn>
                </template>
                <template v-slot:[`item.refe`]="{ item }">
                  {{
                    item.category +
                    "-" +
                    item.brand +
                    "-" +
                    item.refe +
                    item.colorName +
                    "-" +
                    item.talla
                  }}
                </template>
                <template v-slot:[`item.precio`]="{ item }">
                  {{ "$ " + (item.precio * item.cantidad).toLocaleString(2) }}
                  <br />
                  <small
                    class="red--text text-decoration-line-through"
                    v-if="item.discount > 0"
                    >{{
                      "$ " +
                      ((item.discount + item.precio) * item.cantidad).toLocaleString(2)
                    }}
                  </small>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-icon small class="mr-2" @click="delete_item(item)">
                    mdi-delete
                  </v-icon>
                </template>
                <template slot="body.append">
                  <tr>
                    <th>Totales</th>
                    <th class="text-center">
                      {{ sumField(cart, "cantidad").toLocaleString(2) }}
                    </th>
                    <th class="text-right">
                      {{ this.totals.total.toLocaleString(2) }}
                    </th>
                    <th></th>
                  </tr>
                </template>
              </v-data-table>
            </v-col>
            <v-col cols="12" md="6">
              <v-data-table
                :headers="headers_payments"
                :items="payments"
                item-key="id"
                hide-default-footer
                mobile-breakpoint="0"
                sort-by="name"
                class="elevation-1"
                :loading="loading_status"
                loader-height="10"
                dense
                loading-text="Cargando ..."
              >
                <template #top>
                  <v-toolbar flat>
                    <v-toolbar-title>Formas de pago</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn
                      fab
                      icon
                      color="success"
                      @click="payment_dialogo = !payment_dialogo"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </v-toolbar>
                </template>
                <template v-slot:[`item.actions`]="{ item }" class="pa-0 ma-0">
                  <v-icon small @click="delete_payment(item)"> mdi-delete </v-icon>
                </template>
                <template slot="body.append">
                  <tr>
                    <th>Totales</th>
                    <th class="text-right">
                      {{ sumField(payments, "payment_value").toLocaleString(2) }}
                    </th>
                    <th></th>
                  </tr>
                </template>
              </v-data-table>
            </v-col>
            <v-col cols="12" md="6">
              <v-card :loading="loading_status" loader-height="10" flat>
                <v-toolbar flat>
                  <v-toolbar-title>Vendedor</v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                  <v-select
                    v-model="vendor"
                    hide-details
                    :items="vendors"
                    item-value="codigo"
                    item-text="nombre"
                    label=""
                    placeholder="Vendedor"
                    outlined
                    @change="setSku"
                  />
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="6" sm="4">
              <v-text-field
                v-model="bags"
                class="center-input"
                background-color="#FFE0B2"
                label="Bolsas"
                outlined
                hide-details
                prepend-inner-icon="mdi-minus"
                append-icon="mdi-plus"
                @click:prepend-inner="restQty"
                @click:append="addQty"
              />
            </v-col>
            <v-col cols="6" sm="4">
              <v-text-field
                v-model="discount"
                @keyup="discountApply"
                outlined
                label="Descuento"
                class="center-input"
                background-color="#FFCDD2"
                type="number"
                hide-details
                append-icon="mdi-percent-outline"
              />
            </v-col>
            <v-col cols="12" sm="4">
              <v-btn
                class="right-input"
                @click="payment_dialogo = !payment_dialogo"
                block
                color="success"
                :disabled="validate_invoice()"
                x-large
                >{{ "$ " + this.totals.unpaid.toLocaleString(2) }}</v-btn
              >
            </v-col>
          </v-row>
          <div v-for="(itm, index) in validations" :key="index">
            <v-alert
              color="red"
              dense
              elevation="24"
              outlined
              type="error"
              class="mr-4"
              v-if="!itm.status"
            >
              {{ itm.label }}
            </v-alert>
          </div>
        </v-sheet>
      </v-flex>
    </v-layout>

    <v-bottom-navigation fixed app>
      <v-spacer></v-spacer>
      <v-form @submit="get_product" class="pa-2">
        <v-text-field
          v-model="sku"
          id="sku"
          ref="skuID"
          label=""
          :loading="loading_status"
          loader-height="10"
          placeholder="Código de Barras"
          hide-details
          dense
          outlined
          autofocus
          prepend-inner-icon="mdi-barcode-scan"
          append-icon="mdi-help"
          @click:append="search_dialog = !search_dialog"
        >
        </v-text-field>
      </v-form>
      <v-spacer></v-spacer>
    </v-bottom-navigation>

    <v-snackbar
      v-model="skuError"
      :timeout="timeout_error"
      absolute
      centered
      dark
      color="red"
      elevation="24"
    >
      Producto no encontrado en el inventario ...
    </v-snackbar>

    <v-dialog
      v-model="search_dialog"
      transition="dialog-fade-transition"
      scrollable
      max-width="800px"
      :fullscreen="$vuetify.breakpoint.smAndDown"
    >
      <v-form v-model="search_valid" ref="searchform" @submit="search_inventory">
        <v-card>
          <v-card-title class="pa-0">
            <v-toolbar flat>
              <v-toolbar-title>Buscar en el Inventario</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn fab icon @click="search_dialog = !search_dialog">
                <v-icon> mdi-close </v-icon>
              </v-btn>
            </v-toolbar>
          </v-card-title>
          <v-card-text>
            <v-row class="pa-2">
              <v-col cols="6" sm="3">
                <v-select
                  v-model="filters.categoria"
                  :items="CT"
                  item-value="codigo"
                  item-text="valor"
                  label="Categoria"
                  placeholder="Categoria"
                  hide-details="auto"
                  outlined
                />
              </v-col>
              <v-col cols="6" sm="3">
                <v-select
                  v-model="filters.marca"
                  :items="MR"
                  item-value="codigo"
                  item-text="valor"
                  label="Marca"
                  placeholder="Marca"
                  clearable
                  hide-details="auto"
                  outlined
                />
              </v-col>
              <v-col cols="6" sm="3">
                <v-text-field
                  v-model="filters.refe"
                  label="Referencia"
                  clearable
                  hide-details="auto"
                  outlined
                  required
                />
              </v-col>
              <v-col cols="6" sm="3">
                <v-btn
                  :disabled="!search_valid"
                  block
                  color="success"
                  x-large
                  type="submit"
                >
                  Buscar
                </v-btn>
              </v-col>
            </v-row>
            <v-data-table
              :headers="headers"
              :items="inventory_lst"
              :items-per-page="5"
              class="elevation-4"
              mobile-breakpoint="0"
              item-key="ean"
              :loading="loading_status"
              loader-height="10"
              loading-text="Cargando ..."
            >
              <template v-slot:top> </template>
              <template v-slot:[`item.actions`]="{ item }">
                <v-icon class="mr-2" @click="add2cart(item)">
                  mdi-plus-box-outline
                </v-icon>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-form>
      <v-snackbar
        v-model="productAdded"
        :timeout="timeout"
        absolute
        centered
        dark
        color="orange"
        elevation="24"
      >
        Producto agregado ...
      </v-snackbar>
    </v-dialog>

    <v-dialog v-model="edit_dialog" max-width="600px">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar flat rounded>
            <v-toolbar-title>Editar producto</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon fab @click="edit_dialog = !edit_dialog">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar>
        </v-card-title>
        <v-card-text>
          <v-list>
            <v-list-item>
              <v-btn class="mx-1 mr-2" fab icon>
                {{ itemEdit.cantidad }}
              </v-btn>
              <v-list-item-content>
                <v-list-item-title v-text="itemEdit.brand" />
                <v-list-item-subtitle
                  class="text--primary"
                  v-text="itemEdit.refe + '-' + itemEdit.colorName + '-' + itemEdit.talla"
                />
              </v-list-item-content>
              <v-list-item-action>
                {{ "$ " + (itemEdit.precio * itemEdit.cantidad).toLocaleString(2) }}
                <br />
                <span
                  class="red--text text-decoration-line-through"
                  v-if="itemEdit.discount > 0"
                  >{{
                    "$ " +
                    (
                      (itemEdit.discount + itemEdit.precio) *
                      itemEdit.cantidad
                    ).toLocaleString(2)
                  }}
                </span>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-text-field
            @change="updateTotal"
            v-model="itemEdit.cantidad"
            label="Cantidad"
            placeholder="Cantidad"
            class="center-input"
            type="number"
            min="1"
            hide-details
            outlined
          />
          <v-spacer></v-spacer>
          <v-text-field
            v-model="itemEdit.descuento"
            @keyup="itemdiscountApply"
            label="Descuento"
            placeholder="Descuento"
            class="center-input"
            type="number"
            hide-details
            outlined
            append-icon="mdi-percent-outline"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="client_dialog" max-width="600px">
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title>Cliente</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon fab @click="client_dialog = !client_dialog">
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pt-6">
          <v-row>
            <v-col cols="12" sm="6">
              <v-select
                v-model="client.typeid"
                hide-details
                :items="setting['type_document_identifications']"
                item-value="id"
                item-text="name"
                label="Tipo de documento"
                placeholder="Vendedor"
                outlined
              />
            </v-col>
            <v-col cols="12" sm="6">
              <v-text-field
                v-model="client.PartyIdentification"
                label="Número de
              documento"
                ref="idNumber"
                clearable
                hide-details
                outlined
                @keyup="validateID"
                @keypress="filter(event)"
                autofocus
                type="number"
              />
            </v-col>
            <v-col cols="12" sm="12">
              <v-text-field
                v-model="client.PartyName"
                label="Nombre Comopelto"
                hide-details
                outlined
              />
            </v-col>
            <v-col cols="12" sm="8">
              <v-text-field
                v-model="client.AddressLine"
                label="Dirección"
                hide-details
                outlined
              />
            </v-col>

            <v-col cols="12" sm="4">
              <v-select
                v-model="client.CityName"
                hide-details
                :items="setting['municipalities']"
                item-value="id"
                item-text="name"
                label="Ciudad"
                placeholder="Ciudad"
                outlined
              />
            </v-col>
            <v-col cols="12" sm="4">
              <v-text-field
                v-model="client.client_phone"
                label="Celular"
                hide-details
                outlined
                type="number"
                @keypress="filter(event)"
              />
            </v-col>
            <v-col cols="12" sm="8">
              <v-text-field
                v-model="client.client_email"
                label="Correo Electrónico"
                placeholder="Correo Electrónico"
                hide-details
                outlined
                type="email"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn large color="success" type="button" @click="save_client">Guardar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="payment_dialogo" max-width="600px">
      <v-form v-model="payvalid" ref="payform" @submit="addPayment">
        <v-card>
          <v-toolbar flat>
            <v-toolbar-title>Registrar Pagos</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn fab icon @click="payment_dialogo = !payment_dialogo">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text class="pt-6">
            <v-row>
              <v-col cols="12" sm="12">
                <v-select
                  v-model="pay_mode"
                  :items="FP"
                  return-object
                  item-value="codigo"
                  item-text="valor"
                  label="Forma de pago"
                  placeholder="Forma de pago"
                  outlined
                  hide-details
                />
              </v-col>
              <v-col cols="12" sm="8">
                <v-text-field
                  v-model="payment.payment_reference"
                  label="Documento / Referencia"
                  :error="payRefe"
                  @keyup="updateRefe"
                  required
                  outlined
                  hide-details
                />
              </v-col>
              <v-col cols="12" sm="4">
                <v-text-field
                  v-model="payment.payment_value"
                  label="Valor"
                  type="number"
                  class="right-input"
                  :error="payValue"
                  clearable
                  :rule="payRule"
                  outlined
                  required
                  hide-details
                />
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn :disabled="!payvalid" color="success" class="mr-4" type="submit">
              Guardar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>

    <v-dialog v-model="st_dialog" max-width="800px">
      <v-card>
        <v-tabs show-arrows centered class="fixed-top-b">
          <v-tabs-slider></v-tabs-slider>
          <v-tab
            v-for="menu in menus"
            :key="menu"
            :href="'#tab-' + menu"
            @click="changeMenu(menu)"
          >
            {{ menu }}
          </v-tab>
        </v-tabs>
        <v-card-text>
          <v-row class="mt-6">
            <v-col
              class="col-6 col-sm-6 col-md-4"
              v-for="product in menu_selected"
              :key="product.ean"
            >
              <v-card height="100%" elevation="8" @click="add2cart_st(product.ean)">
                <v-img
                  cover
                  aspect-ratio="1"
                  max-height="300px"
                  :src="getImageURL(product.marca, product.refe)"
                >
                </v-img>
                <v-card-title dark>
                  <v-select
                    :items="get_options(product.options)"
                    label="Opciones"
                    item-value="ean"
                    item-text="description"
                  ></v-select>
                </v-card-title>
                <v-card-actions dark class="pa-2 card-actions bgb">
                  <v-spacer></v-spacer>
                  $ {{ product.precio }}
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- <v-dialog v-model="dian_dialog" transition="dialog-fade-transition" scrollable max-width="100%"  :fullscreen="$vuetify.breakpoint.smAndDown">
        <v-card>
          <v-card-title class="pa-0">
            <v-toolbar dark color="success">
              DIAN
              <v-spacer></v-spacer>
              <v-icon @click="dian_dialog = !dian_dialog"> mdi-close </v-icon>
            </v-toolbar>
          </v-card-title>
          <v-card-text class="pt-4" v-if="dian_response.is_valid">
            <v-row>
              <v-col cols="12" sm="4">
                <v-text-field readonly v-model="dian_response.status_description" label="Descripción"
                  hide-details="auto" outlined />
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field readonly v-model="dian_response.status_message" label="Descripción" hide-details="auto"
                  outlined />
              </v-col>
              <v-col cols="12" sm="2">
                <v-text-field readonly v-model="dian_response.issue_date" label="Fecha" hide-details="auto" outlined />
              </v-col>
              <v-col cols="12" md="12" v-if="dian_response.is_valid">
                <iframe :src="dian_response.qr_link" width="100%" height="80vh" frameborder="0" class="cart-list">
                </iframe>
              </v-col>
            </v-row>
            <v-btn light color="success" x-large :href="dian_response.pdf_download_link" target="_blank">
              Descargar PDF
            </v-btn>
          </v-card-text>
          <v-card-text class="pt-4" v-if="dian_response.message">
            <v-alert outlined type="error">
              {{ dian_response.message }}
            </v-alert>
            <pre>{{ JSON.stringify(dian_response.errors, null, 2) }}</pre>
          </v-card-text>
        </v-card>
      </v-dialog> -->

    <!-- <v-navigation-drawer right class="navigation-drawer" :value="showPrint" width="400px" app>
        <v-card height="100vh">
          <v-toolbar dark>
            <h5>Documento Equivalente</h5>
            <v-spacer></v-spacer>
            <v-btn fab dark @click="showPrint = !showPrint">
              <v-icon x-large> mdi-close-circle-outline </v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text class="scroll">
            <v-img height="100%" width="100%" class="white" :src="invoiceImg"></v-img>
          </v-card-text>
        </v-card>
        <v-toolbar class="fixed-footer">
          <v-btn color="success" x-large @click="e_invoice()"> Invoice </v-btn>
          <v-spacer></v-spacer>
          <v-btn color="success" x-large @click="print_doc()"> Imprimir </v-btn>
        </v-toolbar>
      </v-navigation-drawer> -->

    <docViewer
      :item="item"
      :dialog="dialog"
      :newInvoice="nInvoice"
      @close="dialog = false"
      @refresh="refresh_view()"
    />
  </v-container>
</template>

<script>
import { webserver } from "../services/webserver.js";
import createItem from "../utils/createItem.js";
import createClient from "../utils/createClient.js";
import createPayment from "../utils/createPayment.js";
import dianResponse from "../utils/dianResponse.js";
import docViewer from "../components/doc_viewer.vue";
import createDoc from "../utils/create_doc.js";
import settings from "../utils/settings.js";
import createCompany from "../utils/createCompany.js";

export default {
  name: "Home",
  components: { docViewer },
  beforeMount() {
    this.get_data_company();
  },
  mounted() {
    //document.getElementById("sku").focus();
    this.store = window.store;
    this.get_vendors();
    this.FP = window.settings.FP;
    this.CT = window.settings.CATEGORIA;
    this.MR = window.settings.MARCA;
    this.colors = window.settings.COLOR;
    this.seller = window.seller;
    //console.log(this.$store.state.seller);
    //this.get_store_ls();
    // this.print_invoice();
  },
  data() {
    return {
      company: createCompany(),
      doctype: "invoice",
      nInvoice: false,
      store: createClient(),
      connection: null,
      loading_status: false,
      loading_vendors: false,
      itemEdit: createItem(),
      editvalid: false,
      skuError: false,
      timeout: 250,
      timeout_error: 2000,
      edit_dialog: false,
      valid: false,
      productAdded: false,
      payvalid: false,
      search_valid: false,
      client_dialog: false,
      payment_dialogo: false,
      search_dialog: false,
      showPrint: false,
      filters: { categoria: "", marca: "", refe: "", id_pos: "" },
      sku: null,
      cart: [],
      payments: [],
      payRefe: false,
      payValue: false,
      pay_mode: {
        codigo: "01",
        valor: "EFECTIVO",
      },
      payment: createPayment(),
      topay: true,
      discoutRule: (v) => {
        if (!v.trim()) return true;
        if (!isNaN(parseFloat(v)) && v >= 0 && v <= 50) return true;
        return "Debe ser un numero entre 0 y 50";
      },
      requiredRule: [(v) => (v && v !== null) || "Forma de pago requerida"],
      payRule: [
        (v) => !!v || "Forma de pago requerida",
        (v) =>
          (v && v <= this.totals.unpaid) ||
          "Valor max " + this.totals.unpaid.toLocaleString(2),
      ],
      client: createClient(),
      setting: settings(),
      id_lst: [
        { code: "13", name: "Cédula de ciudadanía" },
        { code: "31", name: "NIT" },
        { code: "11", name: "Registro civil" },
        { code: "12", name: "Tarjeta de identidad" },
        { code: "21", name: "Tarjeta de extranjería" },
        { code: "22", name: "Cédula de extranjería" },
        { code: "41", name: "Pasaporte" },
        { code: "42", name: "Documento de identificación extranjero" },
        { code: "50", name: "NIT de otro país" },
      ],
      doctypes: [
        { value: "pos", label: "POS - Documento Equivalente" },
        { value: "invoice", label: "Factura Electrónica" },
      ],
      FP: null,
      CT: null,
      MR: null,
      colors: [],
      headers: [
        {
          text: "Categoría",
          align: "start",
          sortable: true,
          value: "category",
          dataType: "text",
        },
        {
          text: "Grupo",
          align: "start",
          sortable: true,
          value: "groupName",
          dataType: "text",
        },

        {
          text: "Marca",
          align: "start",
          sortable: true,
          value: "brand",
          dataType: "text",
        },
        {
          text: "Referencia",
          align: "start",
          sortable: true,
          value: "refe",
          dataType: "text",
        },
        {
          text: "Color",
          align: "start",
          sortable: true,
          value: "colorName",
          dataType: "text",
        },
        {
          text: "Talla",
          align: "start",
          sortable: true,
          value: "talla",
          dataType: "text",
        },
        {
          text: "Precio",
          align: "end",
          sortable: true,
          value: "precio",
          dataType: "number",
        },
        {
          text: "Descuento",
          align: "end",
          sortable: true,
          value: "descuento",
          dataType: "number",
        },
        { text: "", value: "actions", sortable: false },
      ],
      headers_cart: [
        {
          text: "Descripción",
          align: "start",
          sortable: true,
          value: "refe",
          dataType: "text",
        },
        {
          text: "Qty",
          align: "center",
          sortable: true,
          value: "cantidad",
          dataType: "text",
          width: "10",
        },
        {
          text: "Precio",
          align: "end",
          sortable: true,
          value: "precio",
          dataType: "number",
        },
        { text: "", align: "end", value: "actions", sortable: false, width: "5" },
      ],
      headers_payments: [
        {
          text: "Concepto",
          align: "start",
          value: "payment_concept",
          dataType: "text",
        },

        {
          text: "Valor",
          align: "end",
          sortable: true,
          value: "payment_value",
          dataType: "number",
        },
        { text: "", value: "actions", align: "end", sortable: false },
      ],
      inventory_lst: [],
      vendors: [],
      vendor: null,
      totals: { qty: 0, total: 0, tax: 0, paid: 0, unpaid: 0 },
      bags: 0,
      discount: null,
      dataURL: null,
      invoiceImg: null,
      st_items: [],
      itemsby: null,
      menus: null,
      menu_selected: null,
      menuName: null,
      st_dialog: false,
      eInvoice: null,
      seller: null,
      dian_dialog: false,
      dian_response: dianResponse(),
      docview: "",
      dialog: false,
      item: createDoc(),
      validations: [
        { status: false, label: "Deme suministrar nombre del cliente" },
        { status: false, label: "Correo electrónico es requerido" },
        { status: false, label: "Documento de identificación del Cliente es requerido" },
        { status: false, label: "El tipo de documento es requerido" },
        { status: false, label: "Vendor es requerido" },
        { status: false, label: "Requiere Factura Electrónica" },
        { status: false, label: "Valor" },
      ],
      validForm: false,
      qtyRules: [
        (v) => !!v || "Cantidad es requerida",
        (v) => (v && v >= 0) || "No puede ser cero",
      ],
    };
  },
  methods: {
    get_data_company() {
      var qry = {
        store: window.store.store_id,
        table: "f_companies",
        filters: [
          {
            field: "store_code",
            operator: "=",
            value: window.store.store_id,
          },
          {
            field: "PartyIdentification",
            operator: "=",
            value: window.store.store_business_id,
          },
        ],
      };
      this.loading_status = true;

      webserver("get_dataTable", qry, (data) => {
        this.loading_status = false;
        if (data.length > 0) {
          this.company = data[0];
          this.client.CityName = this.company.city_code;
        }
      });
    },
    validate_doctype(e) {
      if (this.totals.total > 280037 && this.doctype === "pos") {
        this.doctype = "invoice";
      }
      console.log(e);
    },
    sumField(fld, key) {
      var dta = fld;
      return dta.reduce((a, b) => parseFloat(a) + (parseFloat(b[key]) || 0), 0);
    },
    sumFieldPrice(fld, key) {
      var dta = fld;
      return dta.reduce((a, b) => parseFloat(a) + (parseFloat(b[key]) || 0), 0);
    },
    getImageURL: function (marca, refe) {
      var account = window.store.store_id.substring(0, 6);
      var url =
        "https://moda-stc.com/photos/" + account + "/" + marca + "/" + refe + ".jpg";
      if (this.compressed_image) url = this.compressed_image;
      return url;
    },
    scroll() {
      document.getElementById("scroll-target").scrollTop = document.getElementById(
        "scroll-target"
      ).scrollHeight;
    },
    editItem(e) {
      this.itemEdit = e;
      this.edit_dialog = true;
    },
    itemUpdate(e) {
      e.preventDefault();
      this.$refs.editform.validate();
      if (this.editvalid) {
        console.log("Saving");
      }
    },
    get_name_typeId(e) {
      var index = this.setting["type_document_identifications"].findIndex(
        (itm) => itm.id === e
      );
      if (index >= 0) {
        return this.setting["type_document_identifications"][index].name;
      } else {
        return "";
      }
    },
    get_name_city(e) {
      var index = this.setting["municipalities"].findIndex((itm) => itm.id === e);
      if (index >= 0) {
        return this.setting["municipalities"][index].name;
      } else {
        return "";
      }
    },
    restQty() {
      if (this.bags > 0) {
        this.bags = this.bags - 1;
      }
      this.setSku();
    },
    addQty() {
      this.bags = this.bags + 1;
      this.setSku();
    },
    add2cart_st(data) {
      var qry = {
        store: window.store.store_id,
        dbSearch: "inventory",
        filters: {
          id_pos: window.store.store_id,
          ean: data,
        },
      };
      webserver("get_catalog", qry, (data) => {
        data.forEach((itm) => {
          itm.precio = Math.round(parseFloat(itm.precio) * 1.1);
        });
        if (data[0]) {
          this.add2cart(data[0]);
        } else {
          this.skuError = true;
        }
      });
    },
    get_product(e) {
      e.preventDefault();
      this.loading_status = true;
      var qry = {
        store: window.store.store_id,
        dbSearch: "inventory",
        filters: {
          id_pos: window.store.store_id,
          ean: this.sku,
        },
      };
      this.sku = null;
      webserver("get_catalog", qry, (data) => {
        this.loading_status = false;
        console.log(data);
        if (data[0]) {
          this.add2cart(data[0]);
        } else {
          this.skuError = true;
        }
      });
    },
    get_id_barcode(e) {
      //var temp = idCliente.split('\t');
      var temp = e.split(";");
      var u_id = temp[0].trim();
      var u_lastname = (temp[1].trim() + " " + temp[2].trim()).trim();
      var u_firstname = (temp[3].trim() + " " + temp[4].trim()).trim();
      var u_name = (u_firstname + " " + u_lastname).trim();
      var birthday = null;
      var sx = null;
      if (temp[5] === "0M" || temp[5] === "0F") {
        sx = temp[5].replace(/0/g, "");
        birthday =
          temp[6].substring(0, 4) +
          "-" +
          temp[6].substring(4, 6) +
          "-" +
          temp[6].substring(6, 8);
      }
      if (temp[5] === "M" || temp[5] === "F") {
        sx = temp[5];
        birthday =
          temp[6].substring(4, 8) +
          "-" +
          temp[6].substring(2, 4) +
          "-" +
          temp[6].substring(0, 2);
      }
      this.client.PartyIdentification = u_id;
      this.client.PartyName = u_name;
      this.client.client_birthday = birthday;
      this.client.sex = sx;
    },
    discountApply(e) {
      if (e.keyCode === 13) {
        this.cart.forEach((itm) => {
          itm.descuento = parseFloat(this.discount);
          if (itm.discount && itm.discount > 0) {
            itm.precio = itm.precio + itm.discount;
          }
          var factor = parseFloat(itm.descuento) / 100;
          itm.discount = Math.round(itm.precio * factor);
          itm.precio = parseInt(itm.precio - itm.discount);
        });
        this.discount = null;
        this.setSku();
        this.updateTotal();
      }
    },
    itemdiscountApply() {
      //if (e.keyCode === 13) {
      //console.log(this.itemEdit);
      if (this.itemEdit.descuento === "") {
        this.itemEdit.descuento = 0;
      } else {
        this.itemEdit.descuento = parseFloat(this.itemEdit.descuento);
      }
      var factor = parseFloat(this.itemEdit.descuento) / 100;
      this.itemEdit.precio = this.itemEdit.precio + this.itemEdit.discount;
      this.itemEdit.discount = Math.round(this.itemEdit.precio * factor);
      this.itemEdit.item_discount = this.itemEdit.discount;
      this.itemEdit.precio = parseInt(this.itemEdit.precio - this.itemEdit.discount);
      this.updateTotal();

      //}
    },
    filter: function (evt) {
      evt = evt ? evt : window.event;
      let expect = evt.target.value.toString() + evt.key.toString();

      if (!/^[-+]?[0-9]*\.?[0-9]*$/.test(expect)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    validateID(e) {
      if (e.keyCode === 13) {
        var qry = {
          store: window.store.store_id,
          PartyIdentification: this.client.PartyIdentification,
        };
        webserver("get_client_info", qry, (data) => {
          console.log(data);
          if (data) {
            this.client = data;
          }

          //this.loading_status = false;
          // if (data[0]) {
          //   //this.add2cart(data[0]);
          // }
        });
      }
    },
    get_client(e) {
      e.preventDefault();
      var qry = {
        store: window.store.store_id,
        PartyIdentification: this.client.PartyIdentification,
      };
      webserver("get_client_info", qry, (data) => {
        this.client = data;
        //this.loading_status = false;
        // if (data[0]) {
        //   //this.add2cart(data[0]);
        // }
      });

      console.log(e);
    },
    add2cart(e) {
      const newItem = { ...e };
      var STC = 1;
      newItem.qty = parseFloat(newItem.cantidad);

      newItem.precio = parseFloat(newItem.precio) * STC;
      var factor = parseInt(newItem.descuento) / 100;
      newItem.discount = Math.round(newItem.precio * factor);
      newItem.precio = parseInt(newItem.precio - newItem.discount);
      newItem.cantidad = 1;

      // ******************
      var tax_value = 1.19;
      var taxvalue = 19.0;
      newItem.price_amount = (parseFloat(newItem.precio) / tax_value).toFixed(2);
      newItem.item_discount = newItem.discount.toFixed(2);
      newItem.item_tax = (
        (parseFloat(newItem.price_amount) * parseFloat(newItem.cantidad) * taxvalue) /
        100
      ).toFixed(2);

      // ******************

      var index = this.cart.findIndex((itm) => itm.ean === newItem.ean);
      if (index === -1) {
        this.cart.push(newItem);
      } else {
        this.cart[index].cantidad = this.cart[index].cantidad + 1;
      }
      this.productAdded = true;
      this.updateTotal();
    },
    delete_payment(e) {
      var index = this.payments.indexOf(e);
      if (index !== -1) {
        this.payments.splice(index, 1);
      }
      this.updateTotal();
      this.setSku();
    },
    delete_item(e) {
      var index = this.cart.indexOf(e);
      if (index !== -1) {
        this.cart.splice(index, 1);
      }
      this.setSku();
      this.updateTotal();
    },
    addPayment(e) {
      e.preventDefault();
      if (this.pay_mode) {
        if (this.pay_mode.codigo === "01") {
          if (this.payment.payment_value > this.totals.unpaid) {
            var cmb =
              parseFloat(this.payment.payment_value) - parseFloat(this.totals.unpaid);
            this.payment.payment_concept = this.pay_mode.valor;
            this.payment.payment_code = this.pay_mode.codigo;
            this.payment.payment_value = this.totals.unpaid;
            this.payments.push({ ...this.payment });

            var cambio = {
              payment_concept: "EFECTIVO",
              payment_code: "01",
              payment_reference: " Cambio $ " + cmb,
              payment_value: 0,
            };
            this.payments.push({ ...cambio });
            this.updateTotal();
          } else {
            this.payment.payment_concept = this.pay_mode.valor;
            this.payment.payment_code = this.pay_mode.codigo;
            this.payments.push({ ...this.payment });
            this.updateTotal();
          }
          this.pay_mode = {
            codigo: "01",
            valor: "EFECTIVO",
          };
        } else {
          if (this.payment.payment_value <= this.totals.unpaid) {
            this.payment.payment_concept = this.pay_mode.valor;
            this.payment.payment_code = this.pay_mode.codigo;
            this.payments.push({ ...this.payment });
            this.updateTotal();
            this.pay_mode = {
              codigo: "01",
              valor: "EFECTIVO",
            };
          } else {
            this.payValue = true;
            this.payment.payment_value = 0;
          }
        }
      }
    },
    updateRefe() {
      if (this.payment.payment_reference.length > 0) {
        this.payRefe = false;
      }
    },
    validate_invoice() {
      var rspns = false;

      if (this.totals.total > 0) {
        this.validations[6].status = true;
        //rspns = true;
      } else {
        this.validations[6].status = false;
        //rspns = false;
      }

      if (this.totals.total > 280037 && this.doctype === "pos") {
        this.validations[5].status = false;
        //rspns = true;
      } else {
        this.validations[5].status = true;
        //rspns = false;
      }

      if (this.vendor) {
        this.validations[4].status = true;
        // rspns = false;
      } else {
        this.validations[4].status = false;
        // rspns = true;
      }

      if (this.client.typeid) {
        this.validations[3].status = true;
        // rspns = true;
      } else {
        this.validations[3].status = false;
        // rspns = false;
      }

      if (this.client.PartyIdentification) {
        this.validations[2].status = true;
        // rspns = true;
      } else {
        this.validations[2].status = false;
        //rspns = false;
      }
      if (this.client.client_email) {
        var emailTest = String(this.client.client_email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
        console.log(emailTest);
        if (emailTest) {
          this.validations[1].status = true;
        } else {
          this.validations[1].status = false;
        }

        // rspns = true;
      } else {
        if (this.client.PartyName === "CONSUMIDOR FINAL") {
          this.validations[1].status = true;
        } else {
          this.validations[1].status = false;
        }
        // rspns = false;
      }

      if (this.client.PartyName !== "") {
        this.validations[0].status = true;
        //rspns = true;
      } else {
        this.validations[0].status = false;
        // rspns = false;
      }

      // var rspns=true;

      // if (this.totals.total>0 ) {

      //   rspns = false;
      //  } else {

      //   rspns = true;
      //  }
      // var newArray = this.validations.filter(function (el) {
      //   return el.status = true;
      // });

      this.validations.forEach((itm) => {
        //console.log(itm);

        if (!itm.status) {
          rspns = true;
        }
      });

      // return rspns;
      return rspns;

      // console.log(newArray);
      // if (newArray.length>0) {
      //     return true;
      //   } else {
      //     return false;
      //   }

      // if (this.client.PartyIdentification!=='' && this.vendor!=='' && this.client.client_email !=='' && !this.topay) {
      //   return false;
      // } else {
      //   return true;
      // }
    },
    updateTotal() {
      this.totals.qty = 0;
      this.totals.total = 0;
      this.totals.tax = 0;
      this.totals.paid = 0;
      this.totals.unpaid = 0;
      this.cart.forEach((itm) => {
        this.totals.qty += parseFloat(itm.cantidad);
        this.totals.total += parseFloat(itm.cantidad) * parseFloat(itm.precio);
        this.totals.tax +=
          (parseFloat(itm.cantidad) * parseFloat(itm.precio) * parseFloat(19)) / 100;
      });
      this.payments.forEach((itm) => {
        this.totals.paid += parseFloat(itm.payment_value);
      });
      this.totals.unpaid = this.totals.total - this.totals.paid;
      this.payment.payment_value = this.totals.unpaid;
      if (this.totals.unpaid === 0 && this.totals.total > 0) {
        this.payment_dialogo = false;
        this.topay = true;
        this.setSku();
        this.save_invoice();
      } else {
        this.topay = false;
      }
      if (this.totals.total > 280037 && this.doctype === "pos") {
        this.doctype = "invoice";
      }
    },
    get_vendors() {
      var qry = {
        store: window.store.store_id,
      };
      this.loading_vendors = true;
      webserver("vendors", qry, (data) => {
        this.loading_vendors = false;
        console.log(data);
        data.forEach((itm) => {
          itm.nombre = itm.nombre.toUpperCase();
        });
        window.vendors = data;
        this.vendors = data;
      });
    },
    setSku() {
      setTimeout(() => {
        document.getElementById("sku").focus();
      }, 50);
    },
    resetInvoice() {
      this.filters = { categoria: "", marca: "", refe: "", id_pos: "" };
      this.bags = 0;
      this.cart = [];
      this.client = createClient();
      this.payment = createPayment();
      this.payments = [];
      this.client.CityName = this.company.city_code;

      this.updateTotal();
    },
    // print_invoice(data) {
    //   var LS = 50;
    //   var itemsQty = data.items.length * LS;
    //   const elem = document.createElement("canvas");
    //   elem.width = 945;
    //   elem.height = 1800 + itemsQty;
    //   var total = 0;
    //   var center = elem.width / 2;
    //   var left = 10;
    //   var line = 150;
    //   var right = elem.width - left;
    //   const ctx = elem.getContext("2d");

    //   var items = data.items;
    //   items.forEach((item) => {
    //     item.quantity = parseInt(item.quantity);
    //     item.price = parseInt(item.price);
    //     item.discount = parseInt(item.discount);
    //   });
    //   var regimen = "NO RESPONSABLES DEL IVA";

    //   if (data.seller.TaxLevelCode) {
    //     switch (data.seller.TaxLevelCode) {
    //       case "0":
    //         regimen = "NO RESPONSABLES DEL IVA";
    //         break;
    //       case "1":
    //         regimen = "RESPONSABLES DEL IVA";
    //         break;
    //       default:
    //         regimen = "NO RESPONSABLES DEL IVA";
    //     }
    //   }

    //   ctx.font = "bold 40px Arial";
    //   ctx.textAlign = "center";

    //   ctx.font = "bold 60px Arial";
    //   ctx.fillText(data.seller.PartyIdentification_Id, center, line);
    //   line += LS;
    //   ctx.font = "bold 40px Arial";
    //   ctx.fillText(data.seller.PartyName, center, line);
    //   line += LS;
    //   ctx.fillText("Nit : " + data.seller.PartyIdentification, center, line);
    //   line += LS;
    //   ctx.fillText(regimen, center, line);
    //   line += LS;
    //   ctx.fillText(
    //     data.seller.AddressLine + ", " + data.seller.CityName,
    //     center,
    //     line
    //   );
    //   line += LS;
    //   ctx.fillText(
    //     "Resolución DIAN: " + data.permit.InvoiceAuthorization,
    //     center,
    //     line
    //   );
    //   line += LS;
    //   ctx.fillText(
    //     "Del: " +
    //     data.permit.Prefix +
    //     "-" +
    //     data.permit.AuthorizationFrom +
    //     " al " +
    //     data.permit.Prefix +
    //     "-" +
    //     data.permit.AuthorizationTo,
    //     center,
    //     line
    //   );
    //   line += LS;
    //   ctx.fillText(
    //     "Desde: " + data.permit.StartDate + " al " + data.permit.EndDate,
    //     center,
    //     line
    //   );
    //   line += LS;
    //   ctx.font = "bold 35px Arial";
    //   ctx.fillText("Documento Equivalente a la Factura de Venta", center, line);
    //   ctx.font = "bold 40px Arial";
    //   line += LS;
    //   ctx.fillText("No. " + data.document.invoice, center, line);
    //   line += LS;
    //   ctx.fillText("NO SOMOS GRANDES CONTRIBUYENTES", center, line);
    //   line += LS;
    //   ctx.fillText("NO RETENEDORES DE IVA", center, line);
    //   line += LS;
    //   ctx.fillText("Sistema POS", center, line);
    //   line += LS;
    //   if (data.payments[0].hora) {
    //     ctx.fillText(
    //       data.document.issueDate + " " + data.payments[0].hora,
    //       center,
    //       line
    //     );
    //   } else {
    //     ctx.fillText(data.document.issueDate, center, line);
    //   }

    //   line += LS;
    //   ctx.textAlign = "start";
    //   ctx.fillText("C.C./Nit : " + data.buyer.PartyIdentification, left, line);
    //   line += LS;
    //   ctx.fillText("Nombre    : " + data.buyer.PartyName, left, line);
    //   line += LS;
    //   line += LS;
    //   LS = 60;
    //   ctx.font = "bold 50px Arial";
    //   ctx.textAlign = "center";
    //   ctx.fillText("Productos", center, line);
    //   line += LS;
    //   ctx.beginPath();
    //   ctx.moveTo(left, line - 10);
    //   ctx.lineTo(right, line - 10);
    //   ctx.stroke();
    //   line += LS;
    //   total = 0;
    //   var tdiscount = 0;
    //   items.forEach((row) => {
    //     console.log(row);
    //     var price = row.price * row.quantity;
    //     var discount = row.discount * row.quantity;
    //     ctx.textAlign = "start";
    //     ctx.fillText(row.quantity, left, line);
    //     ctx.textAlign = "start";
    //     ctx.fillText(row.description, left + 50, line);
    //     ctx.textAlign = "end";
    //     ctx.fillText(price.toLocaleString(), right, line);
    //     line += LS;
    //     total += price;
    //     tdiscount += discount;
    //   });
    //   ctx.beginPath();
    //   ctx.moveTo(left, line - 10);
    //   ctx.lineTo(right, line - 10);
    //   ctx.stroke();
    //   line += LS;
    //   var tax_value = 1.19;
    //   var sin_iva = Math.round(total / tax_value);
    //   var iva = total - sin_iva;
    //   ctx.textAlign = "start";
    //   ctx.fillText("Base gravable", left, line);
    //   ctx.textAlign = "end";
    //   ctx.fillText(sin_iva.toLocaleString(), right, line);
    //   line += LS;
    //   ctx.textAlign = "start";
    //   ctx.fillText(
    //     "IVA " + Math.round((tax_value - 1) * 100) + "%",
    //     left,
    //     line
    //   );
    //   ctx.textAlign = "end";
    //   ctx.fillText(iva.toLocaleString(), right, line);
    //   line += LS;
    //   ctx.textAlign = "start";
    //   ctx.fillText("TOTAL", left, line);
    //   ctx.textAlign = "end";
    //   ctx.fillText(total.toLocaleString(), right, line);
    //   line += LS;

    //   if (tdiscount > 0) {
    //     ctx.textAlign = "start";
    //     ctx.fillText("Descuento aplicado", left, line);
    //     ctx.textAlign = "end";
    //     ctx.fillText("( " + tdiscount.toLocaleString() + " )", right, line);
    //     line += LS;
    //   }
    //   line += LS;

    //   ctx.textAlign = "center";
    //   ctx.fillText("Formas de pago", center, line);
    //   line += LS;
    //   console.log(data.payments);
    //   data.payments.forEach((row) => {
    //     console.log(row);
    //     ctx.textAlign = "start";
    //     ctx.fillText(row.tipo + " " + row.comprobante, left, line);
    //     ctx.textAlign = "end";
    //     ctx.fillText(row.valor.toLocaleString(), right, line);
    //     line += LS;
    //   });
    //   line += LS;
    //   ctx.textAlign = "center";
    //   if (window.seller.store_footer) {
    //     var res = window.seller.store_footer.split("<br>");
    //     res.forEach((item) => {
    //       ctx.fillText(item, center, line);
    //       line += LS;
    //     });
    //   } else {
    //     ctx.fillText("! Gracias por su compra !", center, line);
    //   }
    //   line += LS;
    //   line += LS;
    //   line += LS;

    //   line += LS;
    //   line += LS;

    //   this.dataURL = elem.toDataURL();
    //   this.invoiceImg = this.dataURL;
    //   this.showPrint = true;
    //   elem.parentNode.removeChild(elem);

    // },
    print_doc() {
      var a = window.open("", "", "height=600, width=400");
      a.document.write('<img src="' + this.dataURL + '"  width="100%">');
      a.print();
      a.document.close();
      setTimeout(() => {
        a.close();
      }, 500);
    },
    search_inventory(e) {
      e.preventDefault();
      this.filters.id_pos = window.store.store_id;
      var qry = {
        store: window.store.store_id,
        dbSearch: "inventory",
        filters: this.filters,
      };

      webserver("get_catalog", qry, (data) => {
        data.forEach((itm) => {
          itm.precio = Math.round(parseFloat(itm.precio) * 1.1);
        });

        this.loading_status = false;
        this.inventory_lst = data;
      });
    },
    get_store_ls() {
      var qry = {
        store: window.store.store_id,
      };
      webserver("get_inventory_st", qry, (data) => {
        this.loading_status = false;
        this.st_items = data;
      });
    },
    changeMenu(e) {
      this.menu_selected = this.itemsby[e];
      console.log(this.menu_selected);
      this.menuName = e;
    },
    get_options(e) {
      return this.groupBy(e.split(","));
    },
    groupBy(arr) {
      var group = [];
      var option = { ean: null, description: null };
      for (let i = 0; i < arr.length - 1; i += 3) {
        console.log(arr[i]);
        var color = "";
        if (this.colors[arr[i + 1]]) {
          color = this.colors[arr[i + 1]].valor;
        } else {
          color = arr[i + 1];
        }
        option = { ean: arr[i], description: color + " - " + arr[i + 2] };
        group.push(option);
        //console.log(option);
      }
      return group;
    },
    save_client() {
      var qry = {
        store: window.store.store_id,
        party: this.client,
      };
      webserver("put_client_info", qry, (data) => {
        //this.resetDoc();
        //this.loading_vendors = false;
        this.client_dialog = false;
        console.log(data);
        //this.print_doc(data);
      });
    },
    save_invoice() {
      var invoice = {
        buyer: this.client,
        doc: {
          doc_type: this.doctype,
          doc_value: this.totals.paid,
          store: window.store.store_id,
          userId: window.profile.user_email,
          vendor: this.vendor,
        },
        items: this.cart,
        payments: this.payments,
        seller: window.seller,
        store: window.store.store_id,
      };
      console.log("post_invoice", invoice);
      webserver("post_invoice", invoice, (data) => {
        console.log(data);
        this.item = { ...data };
        this.dialog = true;
        this.nInvoice = true;
        // this.save_client();
        this.resetInvoice();
        //this.print_doc(data);
        this.loading_vendors = false;
      });
    },
    refresh_view() {
      var e = this.item;
      var issudate = e.issuedate.split(" ");
      var doc = {
        store: e.store,
        issueDate: issudate[0],
        did: e.doc_number,
      };
      webserver("get_invoice_fe", doc, (data) => {
        this.item = data;
        this.dialog = true;
        this.loading_vendors = false;
      });
    },
  },

  watch: {
    bags: function () {
      var idx = this.payments.findIndex((itm) => itm.payment_code === "00");
      if (idx === -1) {
        this.payments.push({
          payment_code: "00",
          payment_concept: "ICB ley 1819 de 2016",
          payment_reference: "",
          payment_value: 60 * this.bags,
        });
      } else {
        this.payments[idx].payment_value = 60 * this.bags;
        if (this.bags <= 0) {
          this.payments.splice(idx, 1);
        }
      }
      idx = this.payments.findIndex((itm) => itm.payment_code === "000");
      if (idx === -1) {
        this.payments.push({
          payment_code: "000",
          payment_concept: "Descuento Impuesto Bolsas",
          payment_reference: "",
          payment_value: -60 * this.bags,
        });
      } else {
        this.payments[idx].payment_value = -51 * this.bags;
        if (this.bags <= 0) {
          this.payments.splice(idx, 1);
        }
      }

      if (this.bags === 0) {
        idx = this.payments.findIndex((itm) => itm.payment_code === "00");
        this.payments.splice(idx, 1);
        idx = this.payments.findIndex((itm) => itm.payment_code === "000");
        this.payments.splice(idx, 1);
        this.updateTotal();
      }
    },
    client_dialog(visible) {
      if (!visible) {
        //console.log(visible);
        this.setSku();
      }
    },
    pay_mode() {
      if (this.pay_mode.codigo === "01") {
        this.payRefe = false;
      } else {
        this.payRefe = true;
      }
      //console.log(this.payRefe);
    },
    payment(e) {
      console.log(e);
    },
    st_items: function (val) {
      var res = val.reduce(function (r, a) {
        r[a.category] = r[a.category] || [];
        r[a.category].push(a);
        return r;
      }, Object.create(null));
      this.itemsby = res;
      this.menus = Object.keys(res);
      if (this.menuName) {
        this.menu_selected = res[this.menuName];
      } else {
        this.menu_selected = res[Object.keys(res)[0]];
      }
    },
  },
  updated() {
    //this.scroll();
  },
  computed: {},
};
</script>

<style lang="scss">
.bg-pos {
  //  background: url("../assets/login.jpg") no-repeat center center fixed;
  background: #cfd8dc;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  height: 100%;
}

.fixed-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.center-input input {
  text-align: center;
  width: 10px;
}

.right-input input {
  text-align: right;
  width: 10px;
}

.scroll {
  overflow-y: scroll;
  max-height: 90vh;
}

.cart-list {
  flex-grow: 1;
  overflow: auto;
  height: 60vh;
}
</style>
